:root {
  --lightness: 24%;
  --goldenrod: 58, 68%;
  --darkcyan: 188, 41%;

  --primary: hsl(var(--darkcyan), var(--lightness));
  --primary-hover: hsl(var(--darkcyan), calc(var(--lightness) - 5%));
}

.p-menuitem-text {
  color: #edf2f7 !important;
}

.p-menuitem-link:hover {
  background-color: #2d3748 !important;
}

.p-menuitem-icon {
  color: #4f5566 !important;
}

/* -------------------------------- CHECKBOX -------------------------------- */

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: var(--primary);
  background: var(--primary);
}

.p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-highlight:hover {
  border-color: var(--primary);
  background: var(--primary-hover);
  color: #ffffff;
}

/* --------------------------------- BUTTON --------------------------------- */

.p-button {
  color: #ffffff;
  background: var(--primary);
  border: 1px solid var(--primary);
}

.p-button:enabled:hover {
  background: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #ffffff;
}

/* ---------------------------- RESPONSIVE TABLE ---------------------------- */

.datatable-responsive-demo
  .p-datatable-responsive-demo
  .p-datatable-tbody
  > tr
  > td
  .p-column-title {
  display: none;
}

@media screen and (max-width: 40em) {
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-thead
    > tr
    > th,
  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tfoot
    > tr
    > td {
    display: none !important;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td {
    text-align: left;
    display: block;
    width: 100%;
    float: left;
    clear: left;
    border: 0 none;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td
    .p-column-title {
    padding: 0.4rem;
    min-width: 30%;
    display: inline-block;
    margin: -0.4em 1em -0.4em -0.4rem;
    font-weight: bold;
  }

  .datatable-responsive-demo
    .p-datatable.p-datatable-responsive-demo
    .p-datatable-tbody
    > tr
    > td:last-child {
    border-bottom: 1px solid var(--surface-d);
  }
}
